import React, { useState, useEffect, lazy, Suspense } from 'react'
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import SignUp from "../pages/SignUp/SignUp";
import { useTranslation } from "react-i18next";
import { FaBars } from "react-icons/fa";
import Dropdown from 'react-bootstrap/Dropdown';
import { useDispatch } from "react-redux";
import { resetApiCall, loaderpage } from '../reduxstate/areasListSlice';
import i18ns from '../reduxstate/i18n';
import logowebpSmall from "../assets/Images/mumtalikatilogred.png"
const CiGlobe = lazy(() => import("react-icons/ci").then(module => ({ default: module.CiGlobe })));

const Topbar = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [modalShow, setModalShow] = useState(false);
  const [collapsednav, setCollapsednav] = useState(true);
  const logowebp = "https://resx.mumtalikati.com/img/mumtalikatilogred.png";
  const webURL=process.env.REACT_APP_WEBSITE_URL;

  const handleToggle = () => {
    setCollapsednav(!collapsednav);
  };
  const isHomePage = location.pathname === webURL;
  const navigate = useNavigate();
  const navLinks = [
    { id: 1, text: t("Home"), href: `${webURL}`, title: 'Discover Properties for Buy, Rent and Sale in Oman | Mumtalikati.com' },
    { id: 2, text: t("PropertyRent"), href: `${webURL}to-Rent/property/oman`, title: 'Properties For Rent In Oman | Mumtalikati.com' },
    { id: 3, text: t("PropertySale"), href: `${webURL}for-Sale/property/oman`, title: 'Properties For Sale In Oman | Mumtalikati.com' },
    { id: 4, text: t("PMS"), href: `${webURL}property-Master-system`, title: 'Post Listings. Manage Tenants. Collect Rent. Track Status. | Mumtalikati.com' },
    { id: 5, text: "Blog", href: `${webURL}blog`, title: 'Blog. | Mumtalikati.com' },
    { id: 6, text: t("Contactus"), href: `${webURL}contactus`, title: 'Mumtalikati | Contact Us' },
  ];

  const handleClick = (event, navLink) => {
    event.preventDefault();
    if (navLink.text === t('FindAgent')) {
      navigate('/');
      setTimeout(() => {
        const element = document.getElementById("findAgent");
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100);
    } else if (navLink.text === t('PropertyRent')) {
      navigate(`/to-Rent/property/oman`);
    } else if (navLink.text === t('PropertySale')) {
      navigate(`/for-Sale/property/oman`);
    } else if (navLink.text === t('Home')) {
      navigate('/');
    }
    else if (navLink.text === "Blog") {
      navigate('/blog');
    }
    else if (navLink.text === t('PMS')) {
      navigate('/property-Master-system');
    }
    else if (navLink.text === t('Contactus')) {
      navigate('/contactus');

    } else {
      handleToggle();
    }
  };

  const supportsWebP = () => {
    const elem = document.createElement('canvas');
    return !!(elem.getContext && elem.getContext('2d')) && elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
  };
  const [isMobile] = useState(window.innerWidth <= 768);

  const [isScrolledDown, setIsScrolledDown] = useState(false);
  const dispatch = useDispatch()
  const { i18n } = useTranslation();
  const [actlang, setActLang] = useState(i18ns.language || 'en');
  // useEffect(() => {
  //     document.body.dir = i18n.dir();
  // }, [i18n, i18n.language]);
  useEffect(() => {
    document.documentElement.lang = i18n.language;
    document.documentElement.dir = i18n.dir();
  }, [i18n.language]);

  const changeLanguage = (lng) => {
    localStorage.setItem('lang', lng);
    dispatch(loaderpage(true));
    i18n.changeLanguage(lng).then(() => {
      setActLang(lng);
      dispatch(resetApiCall());  // Reset API state
      dispatch(loaderpage(false));
    });
  };


  return (
    <header className="border-top-b" style={{ borderBottom: '1px solid rgb(54 51 51 / 7%)' }}>
      <div className='topbg-color '>
        <div className='container'>
          <div className='f1e7e546'>
            <nav className='_4c1686fa'>
              <div className='topbarmargin'>
                <Dropdown>
                  <Dropdown.Toggle variant="" id="dropdown-basic" className="align-items-center border-0 gap-4 d-flex new-topbar" aria-label="Language Selector" >
                    <Suspense fallback={<div>Loading...</div>}>
                      <CiGlobe className="f16" />
                    </Suspense>
                    <span className='ps-2 desktop-only position-relative header-texten' aria-hidden="true">
                      {actlang ? actlang.toUpperCase() : 'EN'}
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu style={{ border: 'none', padding: "0px" }}>
                    {['en-US', 'ar'].map((lng) => (
                      <Dropdown.Item
                        key={lng}
                        onClick={() => changeLanguage(lng)}
                        className={actlang === lng ? 'langactive' : ''}
                      >
                        {lng === 'en-US' ? 'English' : 'العربية'}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </nav>
          </div>
        </div >
      </div >
      <nav className={`navbar navbar-expand-lg bg-white navbar-light main-border container py-4`}>
        <Link to={webURL} title="Discover Properties for Buy, Rent and Sale in Oman | Mumtalikati.com">
          <picture>
            <source
              srcSet={`
      ${logowebpSmall} 400w,
      ${logowebpSmall} 800w,
      ${logowebpSmall} 1200w
    `}
              type="image/webp"
            />
            <img
              src={logowebp}
              alt={t('exploreProperties')}
              loading="lazy"
              className={`mobilelogo ${isHomePage ? "hidden" : ""}`}
              width="140"
              height="40"
            />
          </picture>
          {/* <img
            width="140"
            height="40"
            src={supportsWebP() ? logowebp : logowebp}
            alt={t('exploreProperties')}
            loading="lazy"
            className={`mobile-logo ${isHomePage ? "hidden" : ""}`}
          /> */}
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          aria-controls="navbarContent"
          aria-expanded={!collapsednav ? 'false' : 'true'}
          aria-label="Toggle navigation"
          onClick={handleToggle}
        >
          <FaBars className="icons-bars" />
        </button>
        <div className={`navbar-collapse ${collapsednav ? "collapse" : ""}`} id="navbarContent">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            {navLinks.map((navLink) => (
              <li key={navLink.id} className="nav-item">
                <Link
                  className={`nav-link ${location.pathname === navLink.href ? "active-link" : ""}`}
                  to={navLink.href} title={navLink.title}
                  onClick={(e) => handleClick(e, navLink)}
                >
                  {navLink.text}
                </Link>
              </li>
            ))}
            {/* Dropdown menu */}
            {/* <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle custom-drop" href="/property-Master-system" id="navbarDropdown" role="button" aria-expanded="false">
                PMS
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li><Link className="dropdown-item" to="/service3">Expense Management</Link></li>
                <li><Link className="dropdown-item" to="/service3">Maintenance Requests</Link></li>
                <li><Link className="dropdown-item" to="/service1">Property Master</Link></li>
                <li><Link className="dropdown-item" to="/service2">Rent Collection</Link></li>
                <li><Link className="dropdown-item" to="/service3">Tenant Master</Link></li>
              </ul>
            </li> */}
            <li className={`px-4 topbar-font ${isMobile ? "" : ""}`}>
              <a role="button" className={` AddProperty py-2 px-3 ${isScrolledDown && isHomePage ? "btn-trans-vis" : "btn-trans"
                }
                ${!isHomePage ? "btn-black" : ""}`} title="Proprty Listings" onClick={() => setModalShow(true)}>
                {t("AddProperty")}

              </a>

              <SignUp show={modalShow} onHide={() => setModalShow(false)} />
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Topbar;
