
import { Routes, Route } from "react-router-dom";
import routes from "./Path";

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<routes.Home />} />
      <Route path="/find-agent" element={<routes.FindAgent />} />
      {/* <Route path="/:id" element={<routes.Home />} /> */}
      <Route path="/PrivacyPolicy" element={<routes.PrivacyPolicy />} />
      <Route path="/ApplicationTerms" element={<routes.ApplicationTerms />} />
      <Route path="/UserTermsAndCondition" element={<routes.UsersTermsAndCondition />} />
      <Route path="/SignUp" element={<routes.SignUp />} />
      <Route path="/mobile-app" element={<routes.MobileApp />} />
      {/* pms */}
      <Route path="/property-Master-system" element={<routes.PropertyMangment />} />
      <Route path="/property-Master-system/propertyMaster" element={<routes.PropertyMaster />} />
      <Route path="/property-Master-system/expense_management" element={<routes.ExpenseDetail />} />
      <Route path="/property-Master-system/tenant_management" element={<routes.TenantManagement />} />
      <Route path="/property-Master-system/smart-notifications" element={<routes.SmartNotification />} />
      <Route path="/property-Master-system/maintenance_requests" element={<routes.MaintenanceRequest />} />
      <Route path="/property-Master-system/rent_managements" element={<routes.RentManagement />} />

      <Route
        path="/rent/:bedroom/:address/:city"
        element={<routes.ListingPage />}
      />
      <Route
        path="/rent/:bedroom/:address"
        element={<routes.ListingPage />}
      />
       <Route path="/rent/:address" element={<routes.ListingPage />} />
       <Route
        path="/sale/:bedroom/:address/:city"
        element={<routes.ListingPage />}
      />
      <Route
        path="/sale/:bedroom/:address"
        element={<routes.ListingPage />}
      />
      
       <Route path="/sale/:address" element={<routes.ListingPage />} />

        {/* <Route
          path="/propertydetails/:purpose/:bedroom/:masterType/:address"
          element={<routes.FullViewofCard />}
        />
      <Route
        path="/propertydetails/:purpose/:masterType/:address"
        element={<routes.FullViewofCard />}
      /> */}
      
      <Route path="/blog" element={<routes.Blogs />} />
      <Route path="/blog-detial/:id" element={<routes.BlogDetail />} />

      <Route path="/contactus" element={<routes.Contactpage />} />
      {/* <Route path="*" element={<routes.NotFound />} /> */}
    </Routes>
  );
};

export default Router;
